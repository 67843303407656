<template>
  <mescroll-vue class="box-mescroll" ref="mescroll" :down="mescrollDown" :up="mescrollUp"  @init="mescrollInit">
  <div class="page-check-show-af">
    <div class="box-filter">
      <div class="row">
        <div class="item">
          <a-select v-model="curKey"
            style="width: 3.5rem;"
            placeholder="日期"
             @change="changeCurrent"
            :options="filterOpts"
            />
        </div>
        <div class="item">
          <a-select v-model="query.company_type_id"
            style="min-width: 4.5rem;"
            placeholder="门店类型"
            :allowClear="(optList.companyTypeOtps || []).length>1"
            @change="onSearch(1)"
            :options="optList.companyTypeOtps"/>
        </div>
        <span class="btn" @click="popFilter"><img class="ic" src="@/assets/af_i_filter.png" />筛选</span>
      </div>
      <div v-if="dateSel" class="custom" style="margin-top: 0.5rem;">
          <div class="date">
          <el-date-picker
            v-model="query.start_date"
            type="date"
            align="center"
            prefix-icon=" "
            @change="changeDate"
            value-format="yyyy-MM-dd"
            placeholder="开始日期">
          </el-date-picker>
          <span>至</span>
          <el-date-picker
            v-model="query.end_date"
            type="date"
            align="center"
            prefix-icon=" "
            @change="changeDate"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
          </div>
        </div>
    </div>

    <a-drawer
      title="筛选"
      placement="bottom"
      wrapClassName="pop-filter-af"
      :closable="true"
      :visible="showFilter"
      :handle="true" 
      height="90%"
      @close="hideFilter"
    >

    <div class="box-search">
    <a-form-model :model="query" layout="vertical"
     :label-col="{span: 9}"
     :wrapper-col="{span: 15}" >

      <CascadeFilter ref="cFilter"
        :placeholder="placeholder"
        :hideDepartment="true"
        :showDealerGroup="false"
        :showDealerCode="true"
        :showComType="true"
        :defaultData="query"
        :defaultList="optList"
        @change="onFilterChange"/>
      <a-form-model-item label="服务顾问">
        <SelectSeller :query.sync="query" placeholder="请选择服务顾问"/>
      </a-form-model-item>
      <a-form-model-item label="车牌号码">
        <a-input v-model="query.licence_number" placeholder="请输入车牌号码" :allowClear="true" />
      </a-form-model-item>
      <a-form-model-item label="车架号">
        <a-input v-model="query.car_frame" placeholder="请输入车架号" :allowClear="true" style=""/>
      </a-form-model-item>
      <a-form-model-item label="车主姓名">
        <a-input v-model="query.car_owners" placeholder="请输入车主姓名" :allowClear="true" style=""/>
      </a-form-model-item>
      <a-form-model-item label="标签名称">
        <a-input v-model="query.tag_name" placeholder="请输入标签名称" :allowClear="true" style=""/>
      </a-form-model-item>
      <a-form-model-item label="主工单号">
        <a-input v-model="query.order_no" placeholder="请输入主工单号" :allowClear="true" style=""/>
      </a-form-model-item>

      
      <a-form-model-item label="线索异常">
        <a-radio-group class="group-btn" button-style="solid" v-model="query.exception">
          <a-radio-button :value="0">全部</a-radio-button>
          <a-radio-button :value="1">正常线索</a-radio-button>
          <a-radio-button :value="2">异常线索</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-show="query.exception==2">
        <a-select placeholder="请选择异常线索"
          :allowClear="true"
          v-decorator="[{ initialValue: '0' }]"
          v-model="query.exceptiontype" >
          <a-select-option value="0" >全部</a-select-option>
          <a-select-option value="not_worn" >未佩戴</a-select-option>
          <a-select-option value="not_charge">未充电</a-select-option>
          <a-select-option value="not_uploaded">未上传</a-select-option>
          <a-select-option value="no_cc_card">未绑定铭牌</a-select-option>
          <a-select-option value="hub_not_power">断电（影响数据上传）</a-select-option>
          <a-select-option value="hub_not_net">断网（影响数据上传）</a-select-option>
          <a-select-option value="time_out">录音超长</a-select-option>
<!--          <a-select-option value="passenger_error">客流表迟后</a-select-option>-->
          <a-select-option value="manual_shutdown">铭牌未正常工作</a-select-option>
          <a-select-option value="last_hub_not_power">断电（影响铭牌使用）</a-select-option>
          <a-select-option value="exception">其他情况</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开单日期">
        <div class="custom">
          <div class="date">
          <el-date-picker
            v-model="query.start_date"
            type="date"
            align="center"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="开始日期">
          </el-date-picker>
          <span>至</span>
          <el-date-picker
            v-model="query.end_date"
            type="date"
            align="center"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
      <div v-if="showFilter" class="box-btn">
          <span class="reset" @click="onReset">
            重置
          </span>
          <span class="search" @click="onSearch(1)">
            查询
          </span>
        </div>
    </div>
    </a-drawer>

    <div class="box-list">
      <a-table size="default" ref="table" rowKey="id"
        :locale="{emptyText: '暂无数据'}"
        :loading="loading"
        :pagination="false"
        :columns="columns"
        :dataSource="dataList"
        :customRow="clickTableRow"
      @change="handleTableChange">
        <span slot="index" slot-scope="text,record,index">
          {{ index + 1 }}
        </span>


        <template slot="taskErrorTitle">
          <a-tooltip trigger="click">
            铭牌异常
            <template slot="title">
              未充电：客流前一天晚22点至次日凌晨6点没有插在充电坞上<br>
              未佩戴：客流时间插在充电坞上充电<br>
              未上传：客流当天晚22点至24点未插在充电坞上<br>
              录音超时：超过4个小时的录音<br>
              铭牌未正常工作：铭牌监控到关机且当前电量高于30%<br>
              未绑定铭牌：客流对应的用户未绑定铭牌<br>
              其他情况：请联系运维人员
            </template>
            <a-icon type="question-circle" theme="twoTone" />
          </a-tooltip>
        </template>

        <template slot="hubErrorTitle">
          <a-tooltip trigger="click">
            充电坞异常
            <template slot="title">
              断电（影响铭牌使用）: 客流当天凌晨1点至7点出现断电情况<br>
              断网（影响数据上传）: 客流当天23点至次日凌晨1点出现断网情况<br>
              断电（影响数据上传）: 客流当天23点至次日凌晨1点出现断电情况<br>
            </template>
            <a-icon type="question-circle" theme="twoTone" />
          </a-tooltip>
        </template>

        <span slot="task_error" slot-scope="text, record" >
          <a v-if="record.task_error" style="color: red">
            {{record.task_error.device}}
          </a>
        </span>

        <span slot="hub_error" slot-scope="text, record" >
          <a v-if="record.task_error" style="color: red">
            {{record.task_error.hub}}
          </a>
        </span>


      </a-table>
    </div>
  </div>
    <NavBarDealer v-if="role.isAfDealer || role.isAfDealerBoth" tab="明细列表"></NavBarDealer>
    <NavBar v-else tab="明细列表"></NavBar>
  </mescroll-vue>
</template>

<script>
import Vue from 'vue'
import { postForm, getVolvoAffiliationOtps, getVolvoRoleOtps, getGroupOtpsById, getCompanyOtps, getDepartmentOtpsById, getRegionOtps, getCompanyOtpsById, getBusinessOtps, getEntryStateOtps,gettargetModel} from '@/api'
import {normalDate, toRange} from '@/utils/formatDate'
import pick from 'lodash.pick'
import actions from '@/api/action'
import CascadeFilter from '@/components/CascadeFilter'
import SelectSeller from '@/components/SelectSeller'
import {getRole} from '@/utils/getRole'
import {setDefaultValue} from '@/utils/common'
import NavBar from '@/components/afNavbarOwner.vue';
import NavBarDealer from '@/components/afNavbarDealer.vue';
import MescrollVue from 'mescroll.js/mescroll.vue'
import { getFormatDate } from "@/dbCommon/utils";


export default {
  name: '',
  props: {
  },
  components: {
    MescrollVue,
    NavBar,
    NavBarDealer,
    SelectSeller,
    CascadeFilter
  },
  data() {
    return {
      curKey: "yesterday",
      dateSel: false,
      showFilter: false,
      mescroll: null,
      mescrollDown: {
        use: false,
        auto: false,
      },
      mescrollUp: {
        auto: false,
        dataList: [],
        callback: this.upCallback,
      },
      sellerOtps: [],
      role: null,
      loading: false,
      pageSize: 20,
      total: 10,
      disabled: false,
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      optList: {
      },
      exceptiontype:false,
      query: {
      },
      params: {
      },
      rangeDate: null,
      radioDate: null,
      state: false,
      target_model:[],
      filterOpts: [
        { key: "month", label: "本月" },
        { key: "week", label: "本周" },
        { key: "yesterday", label: "本日" },
        { key: "lastMonth", label: "上月" },
        { key: "custom", label: "自定义" },
      ],
      dataList: [
      ],
      brand_id:'',
      placeholder: {
        crop: '请选择集团',
        dealerGroup: '请选择经销商集团',
        brand: '请选择品牌',
        region: '请选择大区',
        area: '请选择小区',
        company: '请选择公司',
        companyType: '请选择门店类型',
        department: '请选择部门',
      },
    }
  },
  computed:{
    brandLabel() {
      return '品牌名称';
    },
    columns() {
      return  [
        {
          title: "Dealer Code",
          dataIndex: "dealer_code"
        },
        /*
        {
          title: "经销商",
          dataIndex: "dealer_name"
        },
        {
          title: "单据类型",
          dataIndex: "order_type"
        },
        */
        {
          title: "主工单号",
          dataIndex: "order_no"
        },
        {
          title: '服务顾问',
          dataIndex: "seller_name",
          sorter: true,
        },
        {
          title: "进场时间",
          width: '100px',
          dataIndex: "in_time",
        },
        {
          title: "车牌号码",
          dataIndex: "car_plate",
        },
        {
          title: "车架号",
          width: "90px",
          dataIndex: "car_frame_number"
        },
        {
          title: "车主姓名",
          dataIndex: "car_owners"
        },
        {
          title: "车主手机号",
          dataIndex: "user_mobile"
        },
        {
          title: "送修人姓名",
          dataIndex: "car_repair_people"
        },
        {
          title: "开单时间",
          width: '100px',
          dataIndex: "invoice_time",
        },
        {
          title: "标签名称",
          dataIndex: "tag_name"
        },
        {
          title: "评分状态",
          dataIndex: "score_status",
        },
        {
          // title: "异常分析",
          dataIndex: "task_error1",
          slots: { title: 'taskErrorTitle'},
          scopedSlots: { customRender: 'task_error' }

        },
        {
          // title: "异常分析",
          dataIndex: "task_error2",
          slots: { title: 'hubErrorTitle'},
          scopedSlots: { customRender: 'hub_error' }

        },
        {
          title: "总分",
          dataIndex: "score",
          sorter: true,
        },

      ]
    }
  },
  methods: {
    handleCompanyTypeChange(v) {
      this.$refs.cFilter.handleCompanyTypeChange(v);
      this.onSearch(1);
    },
    changeDate() {
      this.onSearch(1);
    },
    changeCurrent(key) {
      if (key == 'custom') {
        this.dateSel = true;
        this.query = {
          ...this.query,
          start_date: '',
          end_date: '',
        };
        return;
      }
      this.dateSel = false;
      const rd = normalDate(toRange(key));
      this.query = {
        ...this.query,
        start_date: rd[0],
        end_date: rd[1],
      };
      this.onSearch(1);
    },
    changeData({resetData={}, optList={}}) {
      this.query = {
        ...this.query,
        ...resetData
      }
      this.optList = {
        ...this.optList,
        ...optList
      }
    },
    hideFilter() {
      this.showFilter = false;
      this.query = {...this.tempQ};
      this.optList = {...this.tempL};
    },
    popFilter() {
      this.showFilter = true;
      this.tempQ = {...this.query};
      this.tempL = {...this.optList};
    },
    upCallback (page){
      this.onSearch(page.num + 1);
    },
    mescrollInit (mescroll) {
      this.mescroll = mescroll;
    },
    clickTableRow(record, index) {
      return {
          style: {
            cursor: 'pointer'
          },
          on: {
             click: () => {
               this.$router.push({name: 'afReceptionShow', query: {id:record.id}});
             }
          }
        }
    },
    
    handleTableChange(pagination, filters, sorter) {
      this.query.field = sorter.field;
      this.query.order = sorter.order;
      this.onSearch(1, this.dataList.length);
    },
    async onFilterChange(query, optList) {
      const {regionOtps, areaOtps, companyOtps} = optList || {};
      this.query = {
        ...this.query,
        ...query,
        region_id: query.region_id || undefined,
        area_id: query.area_id || undefined,
      }
      var brand_id = query.brand_id
      var company_id = query.company_id
      const res = await actions.getCarModelOtps({brand_id:brand_id,company_id:company_id});
      this.optList = {
        ...this.optList,
        regionOtps: regionOtps || [],
        areaOtps: areaOtps || [],
        companyOtps: companyOtps || [],
        target_model: res,
      }
      
    },
    onReset() {
      this.query = {};
      this.rangeDate=null;
      this.radioDate=null;
      this.$refs.cFilter.reset();
      this.query = {
        ...this.query,
        ...setDefaultValue([ {key: 'business', arr: this.optList.businessOpts}])
      };
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onSearch(pageNum, pageSize) {
      this.pageNum = pageNum || this.pageNum;
      if (this.rangeDate) {
        const rd = normalDate(this.rangeDate);
        this.query.start_date = rd[0];
        this.query.end_date = rd[1];
      }
      this.loading = true;
      // const url = '/h5/sa_company/statisticalAnalysis5';
      postForm('/api/b_sa_check/getOrderList', {
        ...this.query,
        //start_date: '2014-06-11',
        //end_date: '2025-06-11',
        pageNum: this.pageNum,
        pageSize: pageSize || this.pageSize,
      }).then(res => {
        this.loading = false;
        if (res.status === 1) {
          const list = res.data || [];
          if (this.pageNum == 1) {
            this.dataList = list;
          } else {
            this.dataList = [...this.dataList, ...list];
          }
          this.pageNum = Number(res.pageNum);
          this.total = Number(res.total);
          this.$nextTick(() => {
            this.mescroll.endSuccess(list.length)
          })
          this.showFilter = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    formatOptionList(list) {
      if (!list) {
        return [];
      }
      return list.filter(o => o).map((o, i) => this.formatOption(String(i), o, o));
    },
    formatOption(key, value, label) {
      return {
        key: String(key), value, label, title: label
      }
    },
  },
  created() {
    this.role = getRole();
    const {brand_id:brandId, crop_id_default:cropId} = Vue.ls.get('USER_INFO') || {};
    const {date_type, ...rest} = this.$router.currentRoute.query || {};
    this.curKey = date_type || 'yesterday';
    if (this.curKey == 'custom') {
      this.dateSel = true;
    } else {
      this.changeCurrent(this.curKey);
    }
    this.query = {
      ...this.query,
      ...rest,
      //crop_id: cropId,
      //brand_id: brandId,
    };
    this.onSearch(1);
  },
  async mounted () {
    this.optList = {
      ...pick(this.$store.state, [
        'validStateOpts',
      ]),
      companyTypeOtps: await actions.getCompanyTypeList({crop_id: this.query.crop_id}),
    }
  }
}
</script>
<style scoped lang="less">

/deep/.ant-select {
    padding-right: 16px;
    position: relative;
    width: auto;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #999999;;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  .ant-select-selection {
    border: 0 !important;
    box-shadow:none !important;
    border-radius: 0 !important;
    background: transparent;

    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-selection__placeholder {
      text-align: left;
      color: #999;
    }
    .ant-select-selection-selected-value {
      width: 100%;
      padding-right: 12px;
      text-align: left;
      color: #4F5154;
    }
  }
  .ant-select-arrow {
    display: none;
  }
}

.pop-filter-af {
  /deep/.ant-drawer-content {
    background: #fff;

    .ant-drawer-wrapper-body {
      display: flex;
      flex-direction: column;
    }

    .ant-drawer-body {
      flex: 1;
      padding: 0.75rem;
      overflow-y: auto;
    }

    .ant-form-item {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
  }
  /deep/.ant-radio-group {
  }
  /deep/.ant-input {
    padding: 0;
    color: #4F5154;
    font-weight: normal;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0;
    text-align: left;
    height: 2rem;
    line-height: 2rem;

    &:focus {
      box-shadow: none;
      outline: none;
      border-bottom: 1px solid #D9D9D9;
    }

    &::-webkit-input-placeholder{
      color: #999; 
      font-size: 0.7rem;
      font-weight: 500;
    }
  }
  /deep/.ant-select {
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    .ant-select-selection {
      background-color: transparent;
      height: 2rem;
      .ant-select-selection__rendered {
        line-height: 2rem;
      }
    }
  }

.custom {
  border-bottom: 1px solid #D9D9D9;
  background: transparent;
  width: 100%;

  /deep/.el-input__inner {
    text-align: center;
    background-color: transparent;
    border: 0;
    padding: 0 0.5rem;
    color: #4F5154;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.7rem;
    font-weight: 500;
    &::-webkit-input-placeholder{
      color: #999; 
      font-size: 0.7rem;
      font-weight: normal;
    }
  }
  .date {
    font-size: 0.6rem;
    padding-left: 0.8rem;
    display: flex;
    align-items: center;
    background: url(~@/assets/af_i_date_d.png) no-repeat 0 0.5rem;
    background-size: 1rem 1rem;
    .el-input__inner {
      padding: 0;
    }
  }
}
  .box-search {
      color: #4F5154;
      font-weight: 500;
      font-size: 0.37333rem;
      padding: 0rem 0 1.5rem 0;

      /deep/ .ant-form-item-label > label {
        color: #666666;
        font-style: normal;
      }
      .item {
        padding: 0 0.5rem;
        margin-bottom: 0.26666rem;
        overflow: hidden;
        border-radius: 2px;

        >.label {
          margin: 0.1rem 0 0.2rem 0;
        }
      }

      .group-btn {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: -0.3rem;

        .ant-radio-button-wrapper {
          box-shadow: none !important;
          margin: 0.3rem 0 0 2%;
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }
        >label {
          height: 2rem;
          line-height: 2rem;
          border-radius: 2rem;
          flex: 1;
          text-align: center;
          background: #E5E8EE;
          font-weight: normal;
          min-width: 32%;
          max-width: 32%;
          outline: none;
          border: 0;

          &:before {
            display: none;
          }

          &.ant-radio-button-wrapper-checked {
            background: #0070C0;
            color: #fff;
          }
        }
      }

      .box-btn {
        margin-left: -0.75rem;
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 750px;
        display: flex;
        span {
          font-size: 0.8rem;
          height: 2.5rem;
          line-height: 2.5rem;
          flex: 1;
          text-align: center;
          font-weight: normal;
          color: #969799;
          background: #F5F5F5;
          &.search {
            color: #fff;
            background: #0070C0;
          }
        }
      }

  }
}

.box-mescroll {
  background: #F2F2F2;
}
.page-check-show-af {

  .box-list {
    margin: 0.75rem;
    overflow: auto;
  }

/deep/.ant-table-thead > tr {
  th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
      background: #E6F1F9;
  }
  th {
    border-radius: 0 !important;
    background: #E6F1F9;
    padding: 0.7rem 0.5rem;
    white-space: nowrap;
    border: 0;
    font-size: 0.7rem;
    color: #666;
    font-weight: normal;
  }
}
/deep/ .ant-table-tbody {
  tr {
    td {
      border: 0;
      border-bottom: 1px solid #d9d9d9;
      border-radius: 0 !important;
      padding: 0.5rem;
      white-space: nowrap;
      font-size: 0.6rem;
    }
  }
  tr:nth-child(odd) {
    background: #fff;
  }
  tr:nth-child(even) {
    background: #fff;
  }
}


  .box-filter {
    background: #F2F2F2 url(~@/assets/af_bg_main_s.png);
    background-size: 100% auto;
    background-attachment: fixed;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
    align-items: center;

    .custom {
      border-bottom: 1px solid #D9D9D9;
      background: transparent;
      width: 100%;

      /deep/.el-input__inner {
        text-align: center;
        background-color: transparent;
        border: 0;
        padding: 0 0.5rem;
        color: #4F5154;
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        font-weight: 500;
        &::-webkit-input-placeholder{
          color: #999; 
          font-size: 0.7rem;
          font-weight: normal;
        }
      }
      .date {
        font-size: 0.6rem;
        padding-left: 0.8rem;
        display: flex;
        align-items: center;
        background: url(~@/assets/af_i_date_d.png) no-repeat 0 0.5rem;
        background-size: 1rem 1rem;
        .el-input__inner {
          padding: 0;
        }
      }
    }
    
    .row {
      height: 2rem;
      background: transparent;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .item {
        flex: 1;
        overflow: hidden;
        padding-right: 1.5rem;
      }
      .item-icon {
        padding-right: 0.5rem;
        font-size: 1rem;
      }
      .btn {
        padding-left: 0.5rem;
        display: inline-block;
        font-size: 0.7rem;
        border-left: 1px solid #D9D9D9;
        text-align: right;
        display: flex;
        align-items: center;

        img {
          height: 0.8rem;
        }
      }
    }
  }
}
</style>
